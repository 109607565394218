@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.btn{
  @apply bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] mobile:px-4 md:text-base mobile:text-lg md:px-7 rounded-lg py-1.5;
}

.btnInvert{
  @apply bg-white text-lightBlue px-5 mr-2 py-1 rounded-lg font-medium transition-all hover:text-white hover:bg-lightBlue;
}

.input{
  @apply border-b border-b-gray-500 bg-transparent px-3 outline-none text-center my-3 pb-1 w-[90%] block mx-auto rounded-md;
}

body {
  margin: 0;
  /* font-family: 'Noto Sans', sans-serif; */
  font-family: 'Nunito', sans-serif;
  overflow-x: hidden;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.offScroll::-webkit-scrollbar {
  display: none;
}

.transitionAddKro::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.transitionAddKro::-webkit-scrollbar-track {
  background-color: #03080E;
}

.transitionAddKro::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background-color: #03080E;
}

::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.innerOf {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.wrapper {
  position: absolute;
  display: flex;
}

section {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.UnderDesign{
  background-color: #DBFF00;
  color: black;
  border: 1px solid black;
}

.NewOrder{
  background-color: #21EC00;
  color: black;
  border: 1px solid black;
}

.Completed{
  background-color: black;
  color: white;
  border: 1px solid white;
}

.PaymentFailed{
  background-color: #FF0000;
  color: white;
  border: 1px solid white;
}

.Active{
  background-color: #21EC00;
  color: black;
  border: 1px solid black;
}

.Disable {
  background-color: #DBFF00;
  color: black;
  border: 1px solid black;
}

.High{
  background-color: #21EC00;
  color: black;
  border: 1px solid black;
}

.Low {
  background-color: #FF0000;
  color: white;
  border: 1px solid white;
}

.Deactivate {
  background-color: #FF0000;
  color: black;
  border: 1px solid black;
}

